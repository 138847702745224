var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.preview && _vm.showPreQuizView)?_c('QuizPreview',{attrs:{"preview":_vm.preview},on:{"onNext":function($event){return _vm.closePreQuizPreview()}}}):_vm._e(),(_vm.preview && _vm.showPostQuizView)?_c('QuizPreview',{attrs:{"preview":_vm.preview},on:{"onNext":function($event){return _vm.closePostQuizPreview()}}}):_c('v-row',{staticClass:"mt-1",attrs:{"id":"keyboard","justify":"center"}},[_c('v-col',{class:{ nextQuestionAnimation: _vm.isNextQuestionAnimationActive },attrs:{"sm":"12","md":"6","xl":"4"}},[(_vm.playOptionsTextAudioOnClick)?_c('AudioPlayer',{attrs:{"speechText":_vm.$t(_vm.$route.name),"settings":_vm.audioSettings,"hideButton":true,"hideSettings":false,"onDarkBackground":true,"useVoiceIcon":true,"initializeAudioLibrary":true}}):_vm._e(),(_vm.complete && !_vm.preview)?_c('div',[_c('ReportCard',{attrs:{"reportData":{
            level: _vm.getLevels[this.selectedLevel].text,
            totalNumberOfQuestions: this.totalNumberOfQuestions,
            correctCount: this.correctCount
          }}}),(_vm.complete && !_vm.preview)?_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pa-1",staticStyle:{"text-align":"center"},attrs:{"cols":"auto"}},[_c('span',[_c('v-btn',{attrs:{"raised":"true","color":"secondary"},on:{"click":function($event){return _vm.restartWithSelectedLevel(_vm.selectedLevel)}}},[_vm._v(_vm._s(_vm.$t('Play Again')))])],1)])],1):_vm._e(),(this.getLevels.length > 1)?_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":"","justify":"center"}},[(_vm.complete)?_c('span',{staticClass:"mr-4",staticStyle:{"text-transform":"uppercase"}},[_vm._v(_vm._s(_vm.$t('Play another level')))]):_vm._e(),_c('LevelSelector',{staticClass:"ml-4",attrs:{"levels":_vm.getLevels,"color":"primary","initialLevel":_vm.selectedLevel,"border":'solid 1px var(--v-primary-base)'},on:{"onSelect":_vm.restartWithSelectedLevel}})],1):_vm._e()],1):_vm._e(),(!_vm.complete && _vm.currentQuestion)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-card',{staticClass:"questionCounterCard",attrs:{"shaped":""}},[_c('div',{staticClass:"questionCounter d-flex",style:({
                backgroundColor: _vm.colorScheme || 'var(--v-primary-base)'
              })},[_c('div',{staticClass:"float-left me-auto"},[_c('span',{staticClass:"font-weight-black white--text"},[_vm._v(" "+_vm._s(_vm.$t('Question ') + (this.currentQuestionIndex + 1) + _vm.$t(' of ') + this.totalNumberOfQuestions)+" ")])]),_c('div',{staticClass:"gameInfo"},[(!_vm.complete)?_c('div',{class:{ scoreCardNormal: !_vm.$vuetify.breakpoint.xs }},[_c('div',{staticClass:"font-weight-black pl-2",style:({ textAlign: 'center' })},[_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":"white"}},[_vm._v("mdi-check-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.correctCount))]),_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":"white"}},[_vm._v("mdi-close-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.currentQuestionIndex + (_vm.questionSubmitted ? 1 : 0) - _vm.correctCount))])],1)]):_vm._e(),_c('div',{staticClass:"pl-4",class:_vm.$vuetify.breakpoint.xs ? 'settingsMobile' : 'settingsNormal',style:({
                    backgroundColor: _vm.colorScheme || 'var(--v-primary-base)'
                  })},[(!_vm.componentData.hideLevelOption)?_c('LevelSelector',{attrs:{"levels":_vm.getLevels,"initialLevel":_vm.selectedLevel},on:{"onSelect":_vm.restartWithSelectedLevel}}):_vm._e(),_c('HowToPlay',{staticClass:"mr-2",attrs:{"i18n_instructions_key":_vm.componentData.instructionsKey ||
                      'sentenceFormingInstruction',"hideLabel":true,"color":"white","iconSize":"x-large"}}),(_vm.playOptionsTextAudioOnClick)?_c('div',{style:({
                      backgroundColor: _vm.colorScheme || 'var(--v-primary-base)',
                    })},[_c('AudioPlayer',{attrs:{"speechText":_vm.currentQuestion.question['gu'],"settings":_vm.audioSettings,"hideButton":true,"onDarkBackground":true,"useVoiceIcon":true}})],1):_vm._e()],1)])]),(_vm.currentQuestion && _vm.currentQuestion.preview && _vm.preview)?_c('v-row',{staticClass:"pa-1",attrs:{"no-gutters":"","justify":"center"}},[(_vm.currentQuestion.preview && !_vm.complete)?_c('QuizPreview',{attrs:{"preview":_vm.preview},on:{"videoEnded":_vm.videoEnded,"videoPaused":_vm.videoPaused,"videoPlay":_vm.videoPlay,"onNext":function($event){return _vm.closePreQuestionPreview()}}}):_vm._e()],1):_c('div',[(
                _vm.componentData.showQuestionHeader &&
                _vm.currentQuestion &&
                !_vm.currentQuestion.isAudio
              )?_c('v-card-title',{class:_vm.questionStyle()},[_vm._v(_vm._s(_vm.questionHeader))]):_vm._e(),(_vm.currentQuestion && _vm.currentQuestion.imagePath)?_c('DisplayImage',{attrs:{"maxHeight":_vm.maxImageHeight,"assetPath":_vm.currentQuestion.imagePath,"fileName":_vm.currentQuestion.imageFileName,"position":_vm.currentQuestion.imagePosition}}):_vm._e(),(_vm.currentQuestion && _vm.currentQuestion.isAudio)?_c('v-card-text',{staticClass:"pa-3 text-center"},[_c('AudioPlayer',{attrs:{"speechText":_vm.currentQuestion.question['gu'],"settings":_vm.audioSettings}})],1):_vm._e(),(!_vm.complete && !_vm.preview && _vm.componentData.showQuestionWords)?_c('v-row',{staticClass:"pa-2 mt-1",style:({
                position: 'relative',
                marginBottom: _vm.componentData.displayHint ? '40px' : '20px'
              }),attrs:{"no-gutters":"","justify":"center"}},[_vm._l((_vm.currentQuestion.wordsState.words),function(wordState,index){return [(wordState.beforeText)?_c('span',{key:'before' + index,style:({ fontSize: 'x-large' })},[_vm._v(_vm._s(wordState.beforeText))]):_vm._e(),(wordState.query || wordState.forceQuery)?_c('BlinkingAnswerBoxWithHint',{key:'query' + index,attrs:{"value":wordState.actual,"size":wordState.wordLength() + 1,"hintOn":_vm.hintOn,"hintWord":wordState.answerHint,"highlightCorrect":_vm.questionSubmitted,"correct":wordState.isAnsweredCorrectly(),"focused":!_vm.questionSubmitted && wordState.hasFocus},nativeOn:{"click":function($event){return _vm.onWordClear(wordState)}}}):_vm._e(),(wordState.afterText != '')?_c('span',{key:'after' + index,style:({ fontSize: 'x-large', paddingRight: '.25em' })},[_vm._v(_vm._s(wordState.afterText))]):_vm._e()]}),_c('input',{staticClass:"sentence-forming-answer-option-cell",style:({ width: '1ch', border: '0', margin: 0 }),attrs:{"type":"text","minlength":"1","required":"required","readonly":true},domProps:{"value":_vm.currentQuestion.punctuation}}),(_vm.componentData.displayHint)?_c('Hint',{staticClass:"hint",on:{"onHintChange":_vm.onHintChange}}):_vm._e()],2):_vm._e()],1),_c('div',{staticClass:"options"},[(
                !_vm.componentData.hidePossibleAnswersPanel &&
                _vm.questionSubmitted &&
                (!_vm.correctAnswerSubmitted ||
                  (_vm.correctAnswerSubmitted &&
                    (_vm.componentData.forceShowPossibleAnswers ||
                      _vm.currentQuestion['gu'].length > 1))) &&
                !_vm.complete
              )?_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex pb-2",style:({ padding: '10px', width: '100%' })},[_c('span',{staticClass:"font-weight-black primary--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.correctSectionTitle))+" : ")]),(_vm.componentData.enableAnswersLocale)?_c('LanguageSelectionSwitchWithDefaults',{on:{"onSelect":_vm.answerLangSelected}}):_vm._e()],1),(
                  !_vm.currentQuestion.highlights &&
                  !_vm.currentQuestion.highlightStatement
                )?_c('div',{style:({ padding: '10px', width: '100%' })},_vm._l((_vm.currentQuestion.question['gu']),function(sentence,index){return _c('div',{key:index,staticClass:"pa-1 pl-1"},[_vm._v(" "+_vm._s(sentence + _vm.currentQuestion.punctuation)+" ")])}),0):_vm._e(),(
                  _vm.currentQuestion.highlightStatement &&
                  !_vm.currentQuestion.highlights
                )?_c('div',{style:({ padding: '10px', width: '100%' })},[_c('div',{staticClass:"pa-4 pl-1"},[_c('HighlightText',{attrs:{"statement":_vm.currentQuestion.question[_vm.answerLang],"highlights":_vm.currentQuestion.answers[_vm.answerLang],"highlightStyle":"highlightGreen"}})],1)]):_vm._e(),(_vm.currentQuestion.highlights)?_c('div',{staticClass:"pa-4 pl-1"},[_c('HighlightText',{attrs:{"statement":_vm.currentQuestion.highlightStatement,"highlights":_vm.currentQuestion.answers[_vm.answerLang],"prefix":_vm.$t('Q.'),"highlightStyle":_vm.correctAnswerSubmitted
                      ? 'highlightGreen'
                      : 'highlightGreen'}}),_vm._l((_vm.currentQuestion.highlights),function(highlight,index){return _c('div',{key:'highlights' + index},[_c('HighlightText',{attrs:{"statement":highlight.statement,"highlights":highlight.replacements,"prefix":_vm.$t('A.'),"highlightStyle":_vm.correctAnswerSubmitted
                        ? 'highlightGreen'
                        : 'highlightGreen'}})],1)})],2):_vm._e()]):_vm._e(),(
                !_vm.preview &&
                _vm.currentQuestion &&
                (!_vm.questionSubmitted ||
                  _vm.componentData.hidePossibleAnswersPanel) &&
                !_vm.complete
              )?_c('v-row',{staticClass:"ma-2 pt-1 pb-1",attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.currentQuestion.optionsState
                  .data),function(optionState,index){return _c('v-col',{key:index,class:_vm.optionsStyle(index),attrs:{"cols":_vm.componentData.kahootStyleOptions ? '6' : 'auto'}},[_c('LetterButton',{key:index,class:!_vm.questionSubmitted ||
                    optionState.enabled ||
                    optionState.isExpectedAnswer
                      ? 'fill-height'
                      : _vm.submissionOptionClass(optionState) + ' fill-height',attrs:{"letter":_vm.translatedOptions(optionState.text),"value":optionState,"draggable":true,"disableUponClick":_vm.componentData.singleChoiceDataMassager || !_vm.allBlankWordsFilled,"gameOver":!optionState.enabled,"assertExpectation":_vm.questionSubmitted,"kahootStyle":_vm.componentData.kahootStyleOptions,"showTranslatedOptions":_vm.componentData.showTranslatedOptions,"expected":optionState.isExpectedAnswer &&
                    optionState.isExpectedAnswer(),"icon":_vm.getIconByIndex(
                      index,
                      optionState.isExpectedAnswer &&
                        optionState.isExpectedAnswer(),
                      !optionState.enabled
                    ),"audioSettings":_vm.audioSettings,"playTextAudioOnClick":_vm.playOptionsTextAudioOnClick},on:{"check":function($event){return _vm.onOptionSelection(optionState)}}})],1)}),1):_vm._e(),(!_vm.preview)?_c('v-row',{staticClass:"pa-2 pb-4",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"}},[(!_vm.complete && !_vm.questionSubmitted)?_c('v-btn',{attrs:{"raised":"true","color":"primary","small":""},on:{"click":_vm.submitAnswer}},[_vm._v(_vm._s(_vm.$t('Submit Answer')))]):_vm._e(),(!_vm.complete && _vm.questionSubmitted)?_c('v-btn',{attrs:{"raised":"true","color":"primary","small":""},on:{"click":_vm.nextQuestion}},[_vm._v(_vm._s(_vm.$t('Next Question')))]):_vm._e()],1)],1):_vm._e()],1),(
              _vm.componentData.kahootStyleOptions &&
              _vm.componentData.enableAudioEffects
            )?_c('div',{staticClass:"volumeSectionMobile ml-3 mr-3"},[_c('RoundVolumeControl',{attrs:{"min":"0","max":"25","startAngle":"-45","endAngle":"225","default":"12.5"},on:{"muted":_vm.toggleAudioEffects,"volumeChange":_vm.volumeChange}})],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('GoBackButton')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }