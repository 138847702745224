<template>
  <v-container>
    <QuizPreview
      v-if="preview && showPreQuizView"
      :preview="preview"
      @onNext="closePreQuizPreview()"
    ></QuizPreview>
    <QuizPreview
      v-if="preview && showPostQuizView"
      :preview="preview"
      @onNext="closePostQuizPreview()"
    ></QuizPreview>

    <v-row v-else id="keyboard" justify="center" class="mt-1">
      <v-col
        sm="12"
        md="6"
        xl="4"
        :class="{ nextQuestionAnimation: isNextQuestionAnimationActive }"
      >
        <!-- initialize /tts cloud function to avoid audio lag -->
        <AudioPlayer
          v-if="playOptionsTextAudioOnClick"
          :speechText="$t($route.name)"
          :settings="audioSettings"
          :hideButton=true
          :hideSettings=false
          :onDarkBackground=true
          :useVoiceIcon=true
          :initializeAudioLibrary=true
        />
        <!--Exercise Complete -->
        <div v-if="complete && !preview">
          <ReportCard
            :reportData="{
              level: getLevels[this.selectedLevel].text,
              totalNumberOfQuestions: this.totalNumberOfQuestions,
              correctCount: this.correctCount
            }"
          />

          <!-- play again -->
          <v-row
            no-gutters
            justify="center"
            class="pa-2"
            v-if="complete && !preview"
          >
            <v-col class="pa-1" cols="auto" style="text-align: center">
              <span>
                <v-btn
                  raised="true"
                  color="secondary"
                  @click="restartWithSelectedLevel(selectedLevel)"
                  >{{ $t('Play Again') }}</v-btn
                >
              </span>
            </v-col>
          </v-row>

          <!-- select level -->
          <v-row
            v-if="this.getLevels.length > 1"
            no-gutters
            justify="center"
            class="pa-2"
          >
            <span
              class="mr-4"
              style="text-transform: uppercase"
              v-if="complete"
              >{{ $t('Play another level') }}</span
            >
            <LevelSelector
              class="ml-4"
              :levels="getLevels"
              color="primary"
              :initialLevel="selectedLevel"
              :border="'solid 1px var(--v-primary-base)'"
              @onSelect="restartWithSelectedLevel"
            ></LevelSelector>
          </v-row>
        </div>

        <v-row no-gutters justify="center" v-if="!complete && currentQuestion">
          <v-card shaped class="questionCounterCard">
              <div
                class="questionCounter d-flex"
                :style="{
                  backgroundColor: colorScheme || 'var(--v-primary-base)'
                }"
              >
                <div class="float-left me-auto">
                  <span class="font-weight-black white--text">
                    {{
                      $t('Question ') +
                      (this.currentQuestionIndex + 1) +
                      $t(' of ') +
                      this.totalNumberOfQuestions
                    }}
                  </span>
                </div>
                <div class="gameInfo">
                  <div
                    :class="{ scoreCardNormal: !$vuetify.breakpoint.xs }"
                    v-if="!complete"
                  >
                    <div
                      :style="{ textAlign: 'center' }"
                      class="font-weight-black pl-2"
                    >
                      <v-icon class="ml-1 mr-1" color="white"
                        >mdi-check-circle-outline</v-icon
                      >
                      <span>{{ correctCount }}</span>
                      <v-icon class="ml-1 mr-1" color="white"
                        >mdi-close-circle-outline</v-icon
                      >
                      <span>{{
                        currentQuestionIndex +
                        (questionSubmitted ? 1 : 0) -
                        correctCount
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="pl-4"
                    :class="
                      $vuetify.breakpoint.xs ? 'settingsMobile' : 'settingsNormal'
                    "
                    :style="{
                      backgroundColor: colorScheme || 'var(--v-primary-base)'
                    }"
                  >
                    <LevelSelector
                      v-if="!componentData.hideLevelOption"
                      :levels="getLevels"
                      :initialLevel="selectedLevel"
                      @onSelect="restartWithSelectedLevel"
                    ></LevelSelector>
                    <!-- <div
                      v-if="
                        componentData.kahootStyleOptions &&
                        !$vuetify.breakpoint.mobile
                      "
                      class="volumeSection ml-3 mr-3"
                    >
                      <RoundSlider
                        v-model="volume"
                        rangeColor="green"
                        handleSize="15"
                        radius="23"
                        width="3"
                        :showTooltip="false"
                        :valueChange="volumeChange()"
                      />
                      <v-icon
                        v-if="!muted"
                        :style="{ position: 'absolute' }"
                        color="white"
                        medium
                        @click="mute()"
                        >mdi-volume-high</v-icon
                      >
                      <v-icon
                        v-else
                        medium
                        :style="{ position: 'absolute' }"
                        color="white"
                        @click="unmute()"
                        >mdi-volume-off</v-icon
                      >
                    </div> -->
                    <!-- <v-icon
                      v-if="componentData.kahootStyleOptions"
                      class="mr-2"
                      color="white"
                      @click="toggleAudioEffects"
                    >
                      {{
                        componentData.enableAudioEffects
                          ? 'mdi-volume-high'
                          : 'mdi-volume-off'
                      }}
                    </v-icon> -->
                    <HowToPlay
                      :i18n_instructions_key="
                        componentData.instructionsKey ||
                        'sentenceFormingInstruction'
                      "
                      :hideLabel="true"
                      color="white"
                      iconSize="x-large"
                      class="mr-2"
                    />
                    <!-- audio settings -->
                    <div
                      v-if="playOptionsTextAudioOnClick"
                      :style="{
                        backgroundColor: colorScheme || 'var(--v-primary-base)',
                      }">
                      <AudioPlayer
                        :speechText="currentQuestion.question['gu']"
                        :settings="audioSettings"
                        :hideButton=true
                        :onDarkBackground=true
                        :useVoiceIcon=true
                      />
                    </div>
                  </div>
                </div>
              </div>
            <v-row
              v-if="currentQuestion && currentQuestion.preview && preview"
              no-gutters
              justify="center"
              class="pa-1"
            >
              <QuizPreview
                v-if="currentQuestion.preview && !complete"
                :preview="preview"
                @videoEnded="videoEnded"
                @videoPaused="videoPaused"
                @videoPlay="videoPlay"
                @onNext="closePreQuestionPreview()"
              ></QuizPreview>
            </v-row>
            <div v-else>
              <v-card-title
                v-if="
                  componentData.showQuestionHeader &&
                  currentQuestion &&
                  !currentQuestion.isAudio
                "
                :class="questionStyle()"
                >{{ questionHeader }}</v-card-title
              >
              <DisplayImage
                v-if="currentQuestion && currentQuestion.imagePath"
                :maxHeight="maxImageHeight"
                :assetPath="currentQuestion.imagePath"
                :fileName="currentQuestion.imageFileName"
                :position="currentQuestion.imagePosition"
              />
              <v-card-text
                v-if="currentQuestion && currentQuestion.isAudio"
                class="pa-3 text-center"
              >
                <AudioPlayer
                  :speechText="currentQuestion.question['gu']"
                  :settings="audioSettings"
                />
              </v-card-text>
              <v-row
                no-gutters
                justify="center"
                :style="{
                  position: 'relative',
                  marginBottom: componentData.displayHint ? '40px' : '20px'
                }"
                class="pa-2 mt-1"
                v-if="!complete && !preview && componentData.showQuestionWords"
              >
                <template
                  v-for="(wordState, index) in currentQuestion.wordsState.words"
                >
                  <!-- non-query word - before -->
                  <span
                    v-bind:key="'before' + index"
                    v-if="wordState.beforeText"
                    :style="{ fontSize: 'x-large' }"
                    >{{ wordState.beforeText }}</span
                  >

                  <BlinkingAnswerBoxWithHint
                    v-bind:key="'query' + index"
                    v-if="wordState.query || wordState.forceQuery"
                    :value="wordState.actual"
                    :size="wordState.wordLength() + 1"
                    :hintOn="hintOn"
                    :hintWord="wordState.answerHint"
                    :highlightCorrect="questionSubmitted"
                    :correct="wordState.isAnsweredCorrectly()"
                    :focused="!questionSubmitted && wordState.hasFocus"
                    @click.native="onWordClear(wordState)"
                  />

                  <!-- non-query word - after -->
                  <span
                    v-bind:key="'after' + index"
                    v-if="wordState.afterText != ''"
                    :style="{ fontSize: 'x-large', paddingRight: '.25em' }"
                    >{{ wordState.afterText }}</span
                  >
                </template>
                <!-- sentence end punctuation -->
                <input
                  type="text"
                  class="sentence-forming-answer-option-cell"
                  minlength="1"
                  required="required"
                  :readonly="true"
                  :style="{ width: '1ch', border: '0', margin: 0 }"
                  :value="currentQuestion.punctuation"
                />
                <Hint
                  v-if="componentData.displayHint"
                  class="hint"
                  @onHintChange="onHintChange"
                />
              </v-row>
            </div>
            <!-- correct answers -->

            <div class="options">
              <v-row
                no-gutters
                v-if="
                  !componentData.hidePossibleAnswersPanel &&
                  questionSubmitted &&
                  (!correctAnswerSubmitted ||
                    (correctAnswerSubmitted &&
                      (componentData.forceShowPossibleAnswers ||
                        currentQuestion['gu'].length > 1))) &&
                  !complete
                "
              >
                <!-- Current Answer / Possible answers / Question-Answer Exapmple  -->
                <div
                  class="d-flex pb-2"
                  :style="{ padding: '10px', width: '100%' }"
                >
                  <span class="font-weight-black primary--text">
                    {{ $t(correctSectionTitle) }} :
                  </span>
                  <LanguageSelectionSwitchWithDefaults
                    v-if="componentData.enableAnswersLocale"
                    @onSelect="answerLangSelected"
                  />
                </div>

                <div
                  v-if="
                    !currentQuestion.highlights &&
                    !currentQuestion.highlightStatement
                  "
                  :style="{ padding: '10px', width: '100%' }"
                >

                  <div
                    class="pa-1 pl-1"
                    v-for="(sentence, index) in currentQuestion.question['gu']"
                    :key="index"
                  >
                    {{ sentence + currentQuestion.punctuation }}
                  </div>
                </div>
                <!-- highlight answer -->
                <div
                  v-if="
                    currentQuestion.highlightStatement &&
                    !currentQuestion.highlights
                  "
                  :style="{ padding: '10px', width: '100%' }"
                >
                  <div class="pa-4 pl-1">
                    <HighlightText
                      :statement="currentQuestion.question[answerLang]"
                      :highlights="currentQuestion.answers[answerLang]"
                      highlightStyle="highlightGreen"
                    />
                    <!-- <div v-else>
                      {{ currentQuestion.question[answerLang] }}
                    </div> -->
                  </div>
                </div>
                <!-- question answer TODO:::Check with Bhaveshbhai -->
                <div v-if="currentQuestion.highlights" class="pa-4 pl-1">
                  <HighlightText
                    :statement="currentQuestion.highlightStatement"
                    :highlights="currentQuestion.answers[answerLang]"
                    :prefix="$t('Q.')"
                    :highlightStyle="
                      correctAnswerSubmitted
                        ? 'highlightGreen'
                        : 'highlightGreen'
                    "
                  />
                  <div
                    v-for="(highlight, index) in currentQuestion.highlights"
                    :key="'highlights' + index"
                  >
                    <HighlightText
                      :statement="highlight.statement"
                      :highlights="highlight.replacements"
                      :prefix="$t('A.')"
                      :highlightStyle="
                        correctAnswerSubmitted
                          ? 'highlightGreen'
                          : 'highlightGreen'
                      "
                    />
                  </div>
                </div>
              </v-row>
              <v-row
                class="ma-2 pt-1 pb-1"
                no-gutters
                justify="center"
                v-if="
                  !preview &&
                  currentQuestion &&
                  (!questionSubmitted ||
                    componentData.hidePossibleAnswersPanel) &&
                  !complete
                "
              >
                <v-col
                  :class="optionsStyle(index)"
                  v-for="(optionState, index) in currentQuestion.optionsState
                    .data"
                  :key="index"
                  :cols="componentData.kahootStyleOptions ? '6' : 'auto'"
                >
                  <LetterButton
                    :letter="translatedOptions(optionState.text)"
                    @check="onOptionSelection(optionState)"
                    :value="optionState"
                    :draggable="true"
                    :disableUponClick="componentData.singleChoiceDataMassager || !allBlankWordsFilled"
                    :gameOver="!optionState.enabled"
                    :assertExpectation="questionSubmitted"
                    :kahootStyle="componentData.kahootStyleOptions"
                    :showTranslatedOptions="componentData.showTranslatedOptions"
                    :expected="
                      optionState.isExpectedAnswer &&
                      optionState.isExpectedAnswer()
                    "
                    :key="index"
                    :class="
                      !questionSubmitted ||
                      optionState.enabled ||
                      optionState.isExpectedAnswer
                        ? 'fill-height'
                        : submissionOptionClass(optionState) + ' fill-height'
                    "
                    :icon="
                      getIconByIndex(
                        index,
                        optionState.isExpectedAnswer &&
                          optionState.isExpectedAnswer(),
                        !optionState.enabled
                      )
                    "
                    v-cloak
                    :audioSettings="audioSettings"
                    :playTextAudioOnClick="playOptionsTextAudioOnClick"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                justify="center"
                class="pa-2 pb-4"
                v-if="!preview"
              >
                <v-col style="text-align: center">
                  <v-btn
                    v-if="!complete && !questionSubmitted"
                    raised="true"
                    color="primary"
                    small
                    @click="submitAnswer"
                    >{{ $t('Submit Answer') }}</v-btn
                  >
                  <v-btn
                    v-if="!complete && questionSubmitted"
                    raised="true"
                    color="primary"
                    small
                    @click="nextQuestion"
                    >{{ $t('Next Question') }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div
              v-if="
                componentData.kahootStyleOptions &&
                componentData.enableAudioEffects
              "
              class="volumeSectionMobile ml-3 mr-3"
            >
              <RoundVolumeControl
                min="0"
                max="25"
                startAngle="-45"
                endAngle="225"
                default="12.5"
                @muted="toggleAudioEffects"
                @volumeChange="volumeChange"
              />
            </div>
          </v-card>
        </v-row>

        <!-- question words -->
      </v-col>
    </v-row>
    <GoBackButton />
  </v-container>
</template>

<script>
import GoBackButton from './GoBackButton'
import Utils from '../util/Utils.js'
import LetterButton from './DraggableLetterButton'
import HowToPlay from './HowToPlay'
import AudioPlayer from './AudioPlayer'
import HighlightText from './HighlightText.vue'
import DisplayImage from './Image.vue'
import Hint from './Hint.vue'
import BlinkingAnswerBoxWithHint from './BlinkingAnswerBoxWithHint.vue'
import LanguageSelectionSwitchWithDefaults from './common/LanguageSelectionSwitchWithDefaults.vue'
import ReportCard from './common/ReportCard.vue'
import QuizPreview from './common/QuizPreview.vue'
import LevelSelector from './common/LevelSelector.vue'
import RoundVolumeControl from './common/RoundVolumeControl.vue'
import { firebaseAnalytics } from '../firebase'
import axios from 'axios'

export default {
  props: {
    componentData: { type: Object, required: true },
    inPlaceReplacementChar: { type: String, required: false },
    colorScheme: String,
    level: { type: String, required: false },
    playOptionsTextAudioOnClick: {type: Boolean, required: false, default: false}
  },
  beforeDestroy() {
    this.pauseAudio(this.backgroundAudio.music)
  },
  mounted() {
    /*
    axios.get('https://us-central1-learn-gujarati-b1468.cloudfunctions.net/tts?text="'
          + this.$t(this.$route.name)
          + '"&lang=gu-IN&speed=0.75&gender=MALE')
    */
    this.backgroundAudio.answerSelected = new Audio(
      require('../assets/selected.mp3')
    )
    this.backgroundAudio.answerCorrect = new Audio(
      require('../assets/correct.mp3')
    )
    this.backgroundAudio.answerIncorrect = new Audio(
      require('../assets/incorrect.mp3')
    )
    this.backgroundAudio.music = this.componentData.audioBackground
    if (this.backgroundAudio.music) {
      this.backgroundAudio.music.loop = true
    }
    this.backgroundAudio.enableAudioEffects = this.componentData.audioBackground
      ? true
      : false
    this.preview = this.componentData.preview
      ? this.componentData.preview
      : undefined
    this.reset()
  },
  components: {
    LetterButton,
    GoBackButton,
    AudioPlayer,
    HowToPlay,
    HighlightText,
    DisplayImage,
    Hint,
    BlinkingAnswerBoxWithHint,
    LanguageSelectionSwitchWithDefaults,
    ReportCard,
    QuizPreview,
    LevelSelector,
    RoundVolumeControl
  },
  data: () => ({
    targetQuestions: [],
    showPreview: false,
    selectedLevel: null,
    correctAnswerSubmitted: false,
    questionSubmitted: false,
    firstEmptyAnswerBoxIndex: 0,
    allBlankWordsFilled: false,
    totalNumberOfQuestions: 0,
    finalScore: 0,
    currentQuestionIndex: -1,
    preview: null,
    correctCount: 0,
    complete: false,
    audioSettings: {
      selectedVoiceGender: 'FEMALE',
      selectedVoiceSpeedPreference: 0.75,
      muted: false
    },
    isNextQuestionAnimationActive: false,
    maxImageHeight: Math.min(window.innerWidth, window.innerHeight * 0.5),
    backgroundAudio: {
      answerSelected: null,
      answerCorrect: null,
      answerIncorrect: null,
      music: null,
      enableAudioEffects: false
    },
    hintOn: false,
    answerLang: 'gu'
  }),
  computed: {
    questionHeader() {
      if (
        this.componentData.showQuestionHeader &&
        this.componentData.doNotShowGujQuestion
      ) {
        if (this.$i18n.locale == 'gu') {
          return this.currentQuestion.question.en
        } else {
          return this.currentQuestion.question[this.$i18n.locale]
        }
      } else {
        return this.currentQuestion.question.gu
      }
    },
    showPreQuizView() {
      return this.currentQuestionIndex == -1 && this.componentData.preQuizView
    },
    showPostQuizView() {
      return (
        this.currentQuestionIndex == this.totalNumberOfQuestions - 1 &&
        this.componentData.postQuizView &&
        this.componentData.postQuizView(
          this.selectedLevel,
          this.getLevelInfo?.name[this.$i18n.locale]
        )
      )
    },
    getLevelInfo() {
      return this.$store.state.practicePlayPages.find(
        (p) => p.id === this.selectedLevel
      )
    },
    getLevels() {
      return this.componentData.levels
    },
    currentQuestion() {
      return this.targetQuestions[this.currentQuestionIndex]
    },

    correctSectionTitle() {
      if (
        this.currentQuestion.highlightStatement &&
        !this.currentQuestion.highlights
      ) {
        if (this.componentData.hasOnlyCorrectAnswer) {
          return 'Correct Answer'
        } else {
          return 'Possible Correct Answers'
        }
      } else if (this.componentData.forceShowPossibleAnswers) {
        return 'Possible Correct Answers'
      } else {
        return 'Question-Answer Example'
      }
    },
    allQuestionsAttempted() {
      return this.currentQuestionIndex + 1 === this.totalNumberOfQuestions
    }
  },

  methods: {
    answerLangSelected(l) {
      this.answerLang = l
    },
    onHintChange(hintStatus) {
      this.hintOn = hintStatus
    },
    playAudio(audio) {
      if (this.backgroundAudio.enableAudioEffects && audio) {
        audio.play()
      }
    },
    toggleAudioMute(audio) {
      if (this.backgroundAudio.enableAudioEffects && audio) {
        audio.toggleMute()
      }
    },
    pauseAudio(audio) {
      if (this.backgroundAudio.enableAudioEffects && audio) audio.pause()
    },
    toggleAudioEffects() {
      this.backgroundAudio.enableAudioEffects =
        !this.backgroundAudio.enableAudioEffects
      if (this.backgroundAudio.enableAudioEffects) {
        this.playAudio(this.backgroundAudio.music)
      } else this.backgroundAudio.music.pause()
    },
    videoEnded() {
      this.playAudio(this.backgroundAudio.music)
    },
    videoPaused() {
      this.playAudio(this.backgroundAudio.music)
    },
    videoPlay() {
      this.pauseAudio(this.backgroundAudio.music)
    },
    getIconByIndex(index, isCorrect, disabled) {
      if (!this.componentData.kahootStyleOptions) return ''
      if (disabled) return isCorrect ? 'mdi-check-outline' : 'mdi-close-outline'
      const shapeIcons = {
        0: 'mdi-triangle-outline',
        1: 'mdi-rhombus-outline',
        2: 'mdi-checkbox-blank-circle-outline',
        3: 'mdi-crop-square'
      }
      const abcdIcons = {
        0: 'mdi-alpha-a-circle',
        1: 'mdi-alpha-b-circle',
        2: 'mdi-alpha-c-circle',
        3: 'mdi-alpha-d-circle'
      }
      return (
        this.componentData.kahootStyleOptionsAbcd ? abcdIcons : shapeIcons
      )[index]
    },
    questionStyle() {
      return (
        'question justify-center pa-3 ' +
        (this.$vuetify.breakpoint.smAndDown ? 'question-sm' : 'question-lg')
      )
    },
    optionsStyle(index) {
      if (!this.componentData.kahootStyleOptions) {
        return 'pa-1  normalStyleOptions'
      }
      const fontClass = this.$vuetify.breakpoint.smAndDown
        ? ' kahootStyleOptions-sm '
        : ''
      return (
        fontClass +
        ' grow kahootStyleOptions kahootStyleOptions-' +
        index +
        ' kahootStyleOptions-' +
        (index % 2 == 0 ? 'even' : 'odd')
      )
    },
    submissionOptionClass(optionState) {
      const isCorrect = optionState.isAnsweredCorrectly()
      if (!this.componentData.kahootStyleOptions) {
        return isCorrect
          ? 'correctAnswerCellKahoot'
          : 'incorrectAnswerCellKahoot'
      } else return isCorrect ? 'correctAnswerCell' : 'incorrectAnswerCell'
    },
    translatedOptions(text) {
      return (
        (this.componentData.options &&
          this.componentData.options[this.$store.state.selectedLanguage] &&
          this.componentData.options[this.$store.state.selectedLanguage][
            text
          ]) ||
        text
      )
    },
    onWordClear(wordState) {
      if (wordState.actual) {
        if (this.questionSubmitted) return
        wordState.onWordClear()
        this.allBlankWordsFilled = false
        this.playAudio(this.backgroundAudio.answerSelected)
      }
    },
    onOptionSelection(optionState) {
      if (this.questionSubmitted) return
      optionState.onOptionSelection()
      this.allBlankWordsFilled = (this.currentQuestion && this.currentQuestion.wordsState.words.find(w => w.hasFocus) == undefined)
      this.playAudio(this.backgroundAudio.answerSelected)
    },
    handleDrop(draggedOptionEvent, wordState) {
      draggedOptionEvent.value.onOptionDrag(wordState)
      this.playAudio(this.backgroundAudio.answerSelected)
    },
    restartExercise() {
      this.pauseAudio(this.backgroundAudio.music)
      if (this.backgroundAudio.music) {
        this.backgroundAudio.music.currentTime = 0
      }
      this.currentQuestionIndex = 0

      this.reset()
    },
    submissionWordClass(wordState) {
      if (this.componentData.hidePossibleAnswersPanel && !wordState.selected) {
        return ''
      }
      return wordState.isAnsweredCorrectly()
        ? 'correctAnswerCell'
        : 'incorrectAnswerCell'
    },
    submitAnswer() {
      this.questionSubmitted = true
      this.correctAnswerSubmitted =
        this.currentQuestion.wordsState.isAnsweredCorrectly()
      if (this.correctAnswerSubmitted) {
        this.correctCount++
      }
      this.playAudio(
        this.correctAnswerSubmitted
          ? this.backgroundAudio.answerCorrect
          : this.backgroundAudio.answerIncorrect
      )
      if (this.currentQuestionIndex === 0) {
        firebaseAnalytics.logEvent('tutorial_begin', {
          name: this.$route.name,
          level: this.getLevels[this.selectedLevel].text
        })
      }
    },
    closePreQuizPreview() {
      this.triggerNextQuestionAnimation()
      this.preview = undefined
      this.playAudio(this.backgroundAudio.music)
      this.scrollToTop()
      this.currentQuestionIndex++
    },
    closePreQuestionPreview() {
      this.preview = undefined
      this.playAudio(this.backgroundAudio.music)
    },
    closePostQuizPreview() {
      this.preview = undefined
      this.showFinalScore()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    triggerNextQuestionAnimation() {
      this.isNextQuestionAnimationActive =
        true && this.componentData.enableTransitionEffects
      setTimeout(this.deactivateNextQuestionAnimationActive, 400)
    },
    deactivateNextQuestionAnimationActive() {
      this.isNextQuestionAnimationActive = false
    },
    nextQuestion() {
      this.allBlankWordsFilled = false
      this.triggerNextQuestionAnimation()
      this.answerLang = 'gu'
      if (this.allQuestionsAttempted) {
        if (this.showPostQuizView) {
          this.preview = this.componentData.postQuizView(
            this.selectedLevel,
            this.getLevelInfo?.name[this.$i18n.locale]
          )
        } else {
          this.showFinalScore()
        }
      } else {
        this.currentQuestionIndex++
        this.questionSubmitted = false
        if (this.currentQuestion.preview) {
          this.preview = this.currentQuestion.preview
        }
        this.$forceUpdate()
      }
    },
    showFinalScore() {
      this.complete = true
      this.finalScore = (
        (this.correctCount / this.totalNumberOfQuestions) *
        100
      ).toFixed(0)
      if (this.finalScore >= 70) {
        Utils.dropConfetti(this.$confetti)
      }
    },
    restartWithSelectedLevel(l) {
      this.selectedLevel = l
      this.restartExercise()
      this.reset()
      this.$forceUpdate()
    },
    reset() {
      this.complete = false
      this.correctCount = 0
      this.preview = undefined
      this.hintOn = false

      if (!this.selectedLevel) {
        this.selectedLevel = decodeURI(this.level || this.getLevels[0].value)
      }

      this.targetQuestions = this.componentData.sentences.filter(
        (q) => q.level == this.selectedLevel
      )
      this.correctAnswerSubmitted = false
      this.questionSubmitted = false
      this.componentData.reset()
      this.totalNumberOfQuestions = this.targetQuestions.length
      this.answerLang = 'gu'
      if (!this.showPreQuizView) {
        this.currentQuestionIndex = 0
        this.preview = undefined
      } else {
        this.preview = this.componentData.preQuizView(
          this.selectedLevel,
          this.getLevelInfo?.name[this.$i18n.locale]
        )
      }

      this.scrollToTop()
    },
    volumeChange(volume) {
      this.backgroundAudio.music.volume = volume / 100
    }
  }
}
</script>
<style scoped>
.hint {
  position: absolute;
  bottom: -45px;
  right: 5px;
}

.options {
  background: #f8f8f8;
  border-bottom-right-radius: 25px;
  border-top: 1px solid var(--v-primary-lighten6);
}
/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.nextQuestionAnimation {
  animation-name: nextQuestionAnimation;
  animation-duration: 1500s;
  animation-iteration-count: infinite;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 5px;
}
.v-card__title {
  word-break: break-word;
}
.gameInfo {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  color: white !important;
}
.settingsMobile {
  position: absolute;
  right: 0;
  top: -45px;
  padding: 4px;
  border-top-left-radius: 20px;
  align-items: center;
  display: flex;
  height: 44px;
}
.scoreCardNormal {
  border-right: solid white;
  padding-right: 16px;
}
.settingsNormal {
  display: flex;
  align-items: center;
}
.levelSelection >>> .v-btn__content {
  opacity: 1 !important;
}
.sentence-forming-answer-option-cell {
  border-bottom: 1px solid #000;
  text-align: 'center';
  margin-bottom: 0.1em;
  font-size: x-large;
  width: auto;
  height: auto;
}
.normalStyleOptions {
  text-align: center;
  font-size: large;
  padding: 0.25em;
}
.kahootStyleOptions {
  flex: 0 0 50%;
  max-width: 100%;
  font-size: x-large;
}
.kahootStyleOptions-sm {
  font-size: medium;
}
.kahootStyleText {
  font-family: Montserrat, 'Noto Sans Arabic', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
}
.question {
  font-weight: 400;
  text-align: center;
}
.question-sm {
  font-size: 20px;
}
.question-lg {
  font-size: 25px;
}
.questionCounter {
  background-color: #f5f5f5;
  padding: 14px;
  text-align: center;
  align-items: center;
}
::v-deep div.kahootStyleText .questionCounter {
  color: white;
  text-align: center;
}
.questionCounterCard {
  width: 100%;
}
::v-deep div.kahootStyleOptions button {
  background-color: inherit;
  box-shadow: none;
  color: white;
  text-align: left;
  width: 100%;
}
::v-deep div.kahootStyleOptions-0 button {
  background: rgb(175, 21, 46);
}
::v-deep div.kahootStyleOptions-1 button {
  background: rgb(15, 81, 160);
}
::v-deep div.kahootStyleOptions-2 button {
  background: rgb(167, 122, 0);
}
::v-deep div.kahootStyleOptions-3 button {
  background: rgb(29, 106, 9);
}
.kahootStyleOptions-even {
  text-align: left;
  margin-top: 5px;
  border-right: 2.5px solid white;
}
.kahootStyleOptions-odd {
  text-align: left;
  margin-top: 5px;
  border-left: 2.5px solid white;
}
.highlightEmptyAnswerBox {
  animation: pulse-red 2s infinite;
}
.correctAnswerCell {
  background-color: green;
  color: white;
}
.incorrectAnswerCell {
  background-color: red;
  color: white;
}
.clearAnswerCell {
  background-color: white;
  color: black;
}

@keyframes pulse-red {
  0% {
    background-color: rgba(217, 84, 89, 0.5);
  }
  70% {
    background-color: rgba(217, 84, 89, 0);
  }
  100% {
    background-color: rgba(217, 84, 89, 0.5);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes nextQuestionAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
