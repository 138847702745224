<template>
  <div>
    <v-btn-toggle
      class="pl-2 pr-2"
      v-model="selectedLang"
      :active-class="'answerLangToggle'"
      rounded
      mandatory
    >
      <v-btn
        class="font-weight-bold"
        v-for="l in displayLangs"
        @click="langSelected(l)"
        :key="l"
        small
      >
        {{ langs[l].selectedDisplay || langs[l].text }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: ['defaultLang'],
  data: () => ({
    selectedLang: 0,
    displayLangs: ['gu', 'en', 'hi']
  }),
  computed: {
    ...mapGetters(['langs']),
    locale() {
      return this.$i18n.locale
    }
  },
  mounted() {},
  methods: {
    langSelected(l) {
      this.$emit('onSelect', l)
    }
  }
}
</script>
<style scoped>
.answerLangToggle {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
</style>
